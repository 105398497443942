export const getPerimeter = (coord) => {
  const { x, y } = coord;
  return Math.max(Math.abs(x), Math.abs(y));
};

//position is special
export const isSpecial = (coord) => {
  const { x, y } = coord;
  return Math.abs(x) === Math.abs(y) || x === +0 || y === +0;
};

//position is center
export const isCenter = (coord) => {
  const { x, y } = coord;
  return x === 0 && y === 0;
};

//spaces length in perimeter
export const getPerimeterCount = (perim) => (perim ? perim * 8 : 1);
