import { getMyTokens } from "ethereum/contract";
import { updateGrid } from "www/grid/grid";
import { isWalletConnected, walletConnect } from "ethereum/wallets";
import { setUpEvents } from "ethereum/events";

export const renderSectionHeader = async () => {
  // const $frame = document.querySelector(".frame");
  const $frameSectionHeader = document.querySelector(".frame__section-header");
  const $headers = document.querySelectorAll("h1,h2");

  let currentHeader;
  setHeader();

  document.addEventListener("scroll", function (e) {
    setHeader();
  });

  function setHeader() {
    for (let $h of $headers) {
      const box = $h.getBoundingClientRect();

      if (
        box.top > 0 &&
        box.top < window.outerHeight &&
        $h.textContent !== currentHeader
      ) {
        $frameSectionHeader.textContent = $h.textContent;
        $frameSectionHeader.classList.add("frame__section-header_filed");
        currentHeader = $h.textContent;
      }
    }
  }
  const button = document.querySelector(".connect-wallet-button");

  if (await isWalletConnected()) {
    button.removeEventListener("click", connectWalletHandler);
    button.style.display = "none";
  } else {
    button.addEventListener("click", connectWalletHandler);
  }
};

async function connectWalletHandler() {
  const address = await walletConnect();
  const button = document.querySelector(".connect-wallet-button");

  button.removeEventListener("click", connectWalletHandler);
  button.style.display = "none";

  await getMyTokens(address, updateGrid);
  setUpEvents(address);
}
