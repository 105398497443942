import { ethers } from "ethers";
import { daiAddress, etheriumNode } from "config";

export const provider = new ethers.providers.JsonRpcProvider(etheriumNode);

// The ERC-20 Contract ABI, which is a common contract interface
// for tokens (this is the Human-Readable ABI format)
const daiAbi = [
  // Mint
  "function mintNFT(int256 xCoords, int256 yCoords) public payable",

  // Getters
  "function getName(uint256 tokenId) public view returns (string memory)",
  "function getCoordsByTokenId(uint256 tokenId) public view returns (int256, int256)",
  "function getOwnedCoords() public view returns (tuple(int256,int256)[] memory)",
  "function getTokenIdByCoords(int256 x, int256 y) public view returns (uint)",
  "function getMessage(uint256 tokenId) public view returns (string memory)",
  "function messageSetPossible(uint256 tokenId) public view returns (bool)",
  "function tokenURI(uint256 tokenId) public view  returns (string memory)",

  //User tokens
  "function balanceOf(address owner) public view returns (uint256)",
  "function tokenOfOwnerByIndex(address owner, uint256 index) public view returns (uint256)",

  // Setters
  "function changeName(uint256 tokenId, string memory newName) public",
  "function setMessage(uint256 tokenId, string memory message) public",

  // Events
  "event SpaceSold(address owner, uint256 id, tuple(int256,int256) coords)",
  "event NameSet(string name, uint256 id, tuple(int256,int256) coords)",
];

// The Contract object
export const contract = new ethers.Contract(daiAddress, daiAbi, provider);
