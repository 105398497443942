export const renderCursor = (e) => {
  const cursorTpl = `
    <div class="map__cursor-center"></div>
    <div class="map__cursor-top"></div>
    <div class="map__cursor-bottom"></div>
    <div class="map__cursor-left"></div>
    <div class="map__cursor-right"></div>
    <div class="map__cursor-position-info"></div>
    <div class="map__cursor-status-info"></div>`;

  const coordinateTpl = (target) =>
    `<span>Plot [ x: ${target.getAttribute("data-x")}, y: ${target.getAttribute(
      "data-y"
    )} ]</span>`;

  const statsTpl = (target) => {
    let status;

    if (target.classList.contains("grid__item-svg_sold-to-you")) {
      status = "Owned by you";
    } else if (target.classList.contains("grid__item-svg_sold-to-other")) {
      status = "Taken";
    } else if (!target.classList.contains("grid__item-svg_locked")) {
      status = "Available";
    } else {
      status = "Not yet available";
    }

    return `<span>${status}</span>`;
  };

  const $map = document.querySelector(".map");
  const $mapCursor = document.createElement("div");
  $mapCursor.classList.add("map__cursor");
  $mapCursor.innerHTML = cursorTpl;
  $map.append($mapCursor);
  const $positionInfo = $mapCursor.querySelector(".map__cursor-position-info");
  const $statusInfo = $mapCursor.querySelector(".map__cursor-status-info");

  let lastTarget;

  document.addEventListener("mousemove", function (e) {
    $mapCursor.style.top = e.clientY + "px";
    $mapCursor.style.left = e.clientX + "px";

    if (e.target !== lastTarget) {
        if (e.target.classList.contains("grid__item-svg")) {  
        $positionInfo.innerHTML = coordinateTpl(e.target);
        $statusInfo.innerHTML = statsTpl(e.target);
        
        } else {
            if (!e.target.classList.contains("svg-grid")){    
                $positionInfo.innerHTML = "";
                $statusInfo.innerHTML = "";
            }
        }
        lastTarget = e.target;
    }
  });

  $map.addEventListener("mouseenter", function () {
    $mapCursor.classList.add("map__cursor_visible");
  });

  $map.addEventListener("mouseleave", function () {
    $mapCursor.classList.remove("map__cursor_visible");
  });
};

export const mapZoom = () => {
  const $controls = document.querySelector(".map__controls");
  const $zoomOut = $controls.querySelector(".map__zoom-out");
  const $zoomIn = $controls.querySelector(".map__zoom-in");

  let currentZoom = 1;
  const minZoom = 1;
  const maxZoom = 4;

  $zoomOut.classList.add("button_disabled");

  $zoomOut.addEventListener("click", () => {
    if (currentZoom !== minZoom) {
      currentZoom--;
      updateZoom(currentZoom);
    }
  });

  $zoomIn.addEventListener("click", () => {
    if (currentZoom !== maxZoom) {
      currentZoom++;
      updateZoom(currentZoom);
    }
  });

  function updateZoom(zoom) {
    const $svgGrid = document.querySelector(".svg-grid");
    $svgGrid.setAttribute("data-zoom", zoom);

    if (currentZoom === minZoom) {
      $zoomOut.classList.add("button_disabled");
      $zoomIn.classList.remove("button_disabled");
    } else if (currentZoom === maxZoom) {
      $zoomIn.classList.add("button_disabled");
      $zoomOut.classList.remove("button_disabled");
    } else {
      $zoomIn.classList.remove("button_disabled");
      $zoomOut.classList.remove("button_disabled");
    }
  }
};
