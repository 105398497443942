export const colorsClear = [
  "#27A9C7",
  "#33AECA",
  "#3AB5D1",
  "#3DBBD8",
  "#45C0DC",
  "#63C2D5",
  "#7ECDDC",
];

export const colorsSold = [
  "#7b68ee",
  "#7663e8",
  "#705ce4",
  "#715bf1",
  "#6a54e8",
];

export const colorsYou = ["#ee6969", "#e45e5e", "#f15a5a", "#e45858"];

export const colorsLocked = [
  "#e4eef1",
  "#e8f1f3",
  "#e1ecef",
  "#e6f0f3",
  "#e5eff2",
  "#edf3f5"
];
