import { ethers } from "ethers";
import { provider, web3Provider } from "ethereum/providerWC";
import { analytics } from "index";

const { ethereum } = window as any;
export async function isWalletConnected() {
  if (ethereum) {
    const {
      result: [address],
    } = await ethereum.send("eth_accounts");

    return !!address;
  }

  return provider.connected;
}

export async function getWalletAddress() {
  if (ethereum) {
    const {
      result: [address],
    } = await ethereum.send("eth_accounts");

    return address;
  }
}

export async function walletConnect() {
  analytics.logEvent("connect_wallet_initiate");
  try {
    if (ethereum) {
      const [address] = await ethereum.request({
        method: "eth_requestAccounts",
      });
      analytics.logEvent("connect_wallet_success", {
        wallet_public_key: address,
        type: "injected",
      });

      return address;
    } else {
      const [address] = await provider.enable();
      analytics.logEvent("connect_wallet_success", {
        wallet_public_key: address,
        type: "wallet_connect",
      });
      return address;
    }
  } catch (e) {
    analytics.logEvent("connect_wallet_failure", {
      error: e.message,
    });
    throw Error(e);
  }
}

export async function getProvider() {
  if (ethereum) {
    const provider = new ethers.providers.Web3Provider(ethereum);
    return provider;
  } else {
    return web3Provider;
  }
}

export async function getSigner() {
  const provider = await getProvider();
  return await provider.getSigner();
}
