import { BigNumber, utils } from "ethers";
import { contract } from "./provider";
import { getSigner } from "ethereum/wallets";

export const mintToken = async ({
  price,
  x,
  y,
}: {
  price: string;
  x: string;
  y: string;
}) => {
  const signer = await getSigner();

  if (!signer) {
    return {};
  }

  const signedContract = contract.connect(signer);
  const resultGas = await signedContract.estimateGas.mintNFT(x, y, {
    value: BigNumber.from(price),
  });
  const result = await signedContract.mintNFT(x, y, {
    gasLimit: resultGas.toString(),
    value: BigNumber.from(price),
  });
  return result;
};

export const changeNameForToken = async (tokenId: string, name: string) => {
  const signer = await getSigner();

  if (!signer) {
    return;
  }
  const signedContract = contract.connect(signer);

  try {
    await signedContract.changeName(tokenId, name);
  } catch (e) {
    console.log(e);
  }
};

export const changeMessageForToken = async (
  tokenId: string,
  message: string
) => {
  const signer = await getSigner();

  if (!signer) {
    return;
  }
  const signedContract = contract.connect(signer);

  try {
    await signedContract.setMessage(tokenId, message);
  } catch (e) {
    console.log(e);
  }
};

export const getGasForMessage = async (tokenId: string, message: string) => {
  const signer = await getSigner();

  if (!signer) {
    return;
  }
  const signedContract = contract.connect(signer);

  try {
    const resultGas = await signedContract.estimateGas.setMessage(
      tokenId,
      message
    );
    return utils.formatEther(
      BigNumber.from(resultGas.toNumber()).mul(30000000000)
    );
  } catch (e) {
    console.log(e);
  }
};

export const getTokenMessage = async (tokenId: string) => {
  const signer = await getSigner();

  if (!signer) {
    return;
  }
  const signedContract = contract.connect(signer);

  try {
    const result = await signedContract.getMessage(tokenId);
    return result.toString();
  } catch (e) {
    console.log(e);
  }
};

export const messageSetPossible = async (tokenId: string) => {
  const signer = await getSigner();

  if (!signer) {
    return;
  }
  const signedContract = contract.connect(signer);

  try {
    const result = await signedContract.messageSetPossible(tokenId);
    return result.toString();
  } catch (e) {
    console.log(e);
    return "false";
  }
};
