import createAuth0Client from "@auth0/auth0-spa-js";
import { pageLock } from "../page/page.js";
import { template, updateUserName } from "./template.js";
import { renderShare } from "../share/share.js";
import {
  getPriceForCoords,
  getTokenIdByCoords,
  getTokenName,
} from "ethereum/contract";
import {
  mintToken,
  messageSetPossible,
  changeNameForToken,
  changeMessageForToken,
  getGasForMessage,
  getTokenMessage,
} from "ethereum/signer";
import { decrypt, encrypt } from "ethereum/crypto";
import { isWalletConnected, walletConnect } from "ethereum/wallets";
import { setUpEvents } from "ethereum/events";
import { analytics } from "index";
import { twitterSpaces } from "config";

const $product = document.querySelector(".product");

export const showPopup = async (data) => {
  const auth0 = await createAuth0Client({
    domain: "dev-a9p6-vrs.eu.auth0.com",
    client_id: "jTEMbbpcmIYjJE1COzOd9EFhmBBahrZY",
  });

  window.history.pushState(
    "",
    `Gone to Mars [x:${data.coord.x},y:${data.coord.y}]`,
    `${window.location.origin + window.location.pathname}?x=${data.coord.x}&y=${
      data.coord.y
    }`
  );
  document.title = `Gone to Mars [x:${data.coord.x},y:${data.coord.y}]`;

  const isMy = data.owner.isMy;
  const ownedByOther = data.status === "Taken";
  const notAvailable = data.status === "Not yet available";
  const isSold = data.status === "Taken" || data.status === "Owned by you";
  const revealPhraseDate = 2537654400 <= Date.now() / 1000;

  const twitterSpace = IsTwitterSpace(data.coord.x, data.coord.y);

  /**
   * First draw
   */
  const $productPopup = document.createElement("div");
  $productPopup.classList.add("product__popup");
  $product.innerHTML = "";
  $product.append($productPopup);
  $productPopup.innerHTML = template({
    data,
    platform: null,
    tokenName: null,
    isMy,
    notAvailable,
    ownedByOther,
    isSold,
    price: null,
    revealPhraseDate,
    tokenId: null,
  });
  $product.classList.remove("product_time-capsule");

  const price = await getPriceForCoords(data.coord.x, data.coord.y);
  const tokenId = isSold ? await getTokenIdByCoords(data.coord) : 0;
  const isMessageSetPossible = isMy
    ? await messageSetPossible(tokenId)
    : "false";

  console.log({ isMessageSetPossible });
  const nameFromContract = await getTokenName(tokenId);
  const [platform, nickname] = nameFromContract?.split(":") ?? [];
  const tokenName = nickname || "Anonymous";

  analytics.logEvent("space_select", {
    coordinates: data.coord,
    price: price,
    status: data.status,
  });

  if (twitterSpace && (!isMy || !isSold)) {
    const popupTpl = template({
      data,
      platform: "twitter",
      tokenName: twitterSpace.nickname,
      isMy: false,
      notAvailable: false,
      ownedByOther: false,
      isSold: false,
      price,
      revealPhraseDate,
      tokenId,
      isReserved: true,
      isMessageSetPossible,
    });
    $productPopup.innerHTML = popupTpl;
    pageLock(true);
    const verifyTwitterButton = document.querySelector(
      ".product__popup-content-verify-twitter"
    );

    verifyTwitterButton.addEventListener("click", async () => {
      const verifyed = await verifyTwitter(
        auth0,
        data.coord,
        twitterSpace.nickname
      );

      if (!verifyed) {
        alert(
          `Nice try, but this Plot is reserved for ${twitterSpace.nickname}. Choose another one before it’s gone…`
        );
        await auth0.logout({
          returnTo: window.location,
        });
      }

      const popupTpl = template({
        data,
        platform: "twitter",
        tokenName: twitterSpace.nickname,
        isMy: false,
        notAvailable: false,
        ownedByOther: false,
        isSold: false,
        price,
        revealPhraseDate,
        tokenId,
        isReserved: false,
        isMessageSetPossible,
      });
      $productPopup.innerHTML = popupTpl;
    });

    setEventListeners($productPopup);
    return;
  }
  /*
  Draw with data
  **/
  const popupTpl = template({
    data,
    platform,
    nickname,
    tokenName,
    isMy,
    notAvailable,
    ownedByOther,
    isSold,
    price,
    revealPhraseDate,
    tokenId,
    isMessageSetPossible,
  });
  $productPopup.innerHTML = popupTpl;
  pageLock(true);

  const capsuleBeforeSet = document.querySelector(".time-capsule-before-set");
  const capsuleAfterSet = document.querySelector(".time-capsule-after-set");
  if (capsuleAfterSet !== null && isMessageSetPossible === "true") {
    capsuleAfterSet.style.display = "none";
  }
  if (capsuleBeforeSet !== null && isMessageSetPossible === "false") {
    capsuleBeforeSet.style.display = "none";
  }

  setEventListeners($productPopup);

  function setEventListeners($productPopup) {
    /*
    Events
  **/
    $productPopup.addEventListener("click", (e) => {
      const $target = e.target;

      //CLICK
      //tab change
      if ($target.classList.contains("product__popup-tabs-header-label")) {
        let $tabs = $product.querySelectorAll(
          ".product__popup-tabs-header-label"
        );
        for (let $t of $tabs) {
          $t.classList.remove("product__popup-tabs-header-label_active");
        }
        $target.classList.add("product__popup-tabs-header-label_active");
        if ($target.getAttribute("for") === "time-capsule") {
          $product.classList.add("product_time-capsule");
        } else {
          $product.classList.remove("product_time-capsule");
        }
      }

      //CLICK
      //close popup
      if ($target.classList.contains("product__popup-close")) {
        window.history.pushState(
          "",
          `Gone to Mars`,
          `${window.location.origin + window.location.pathname}`
        );
        document.title = `Gone to Mars`;
        closePopup();
      }

      //CLICK
      //connect social network
      if (
        $target.classList.contains(
          "product__popup-content-connect-social-network-button"
        )
      ) {
        connectProfile(auth0, tokenId, data);
      }

      //CLICK
      //buy button
      if ($target.classList.contains("product__popup-content-buy-button")) {
        buy(data.coord, price);
      }

      //CLICK
      //time capsule submit
      if (
        $target.classList.contains(
          "product__popup-content-set-time-capsule-button"
        )
      ) {
        setMessage(tokenId);
      }

      //CLICK
      //time capsule get price
      if (
        $target.classList.contains(
          "product__popup-content-time-capsule-get-gas-button"
        )
      ) {
        getPharsePrice(tokenId);
      }

      if (
        $target.classList.contains(
          "product__popup-content-time-capsule-get-message"
        )
      ) {
        const passInput = document.querySelector(
          ".product__popup-content-time-capsule-password-reveal-input"
        );
        getMessage(tokenId, passInput.value);
      }

      //CLICK
      //share button
      if ($target.classList.contains("button_share")) {
        e.preventDefault();
        const shareData = {
          title: "Gone to Mars",
          text: `https://gonetomars.com/?x=${data.coord.x}&y=${data.coord.y}`,
          url: `https://gonetomars.com/?x=${data.coord.x}&y=${data.coord.y}`,
        };

        try {
          navigator.share(shareData);
        } catch (err) {
          renderShare();
        }
      }
    });
  }
};

export const closePopup = () => {
  $product.innerHTML = "";
  pageLock(false);
};

async function buy(coords, price) {
  analytics.logEvent("purchase_initiate", {
    coordinates: coords,
    price: price,
  });
  const $buyButton = document.querySelector(
    ".product__popup-content-buy-button"
  );
  const $spiner = document.createElement("div");
  const $productPopupControll = document.querySelector(
    ".product__popup-content-controls"
  );

  $buyButton.style.display = "none";
  $spiner.classList.add("spiner");
  $spiner.innerHTML = `<svg
      class="graph"
      width="99"
      height="157"
      viewBox="0 0 99 157"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 108.8L46.2039 155L86.699 115.376M1 108.8L46.2039 115.376M1 108.8L35.8447 1M1 108.8V56.2735L35.8447 1M46.2039 115.376H86.699M46.2039 115.376L98 78M46.2039 115.376L35.8447 1M86.699 115.376L98 78M98 78L35.8447 1M98 78L77.5789 24.1L35.8447 1"
      />
    </svg>`;
  $productPopupControll.appendChild($spiner);

  try {
    if (await isWalletConnected()) {
      await mintToken({ price, x: coords.x, y: coords.y });
    } else {
      const address = await walletConnect();
      setUpEvents(address);
      await mintToken({ price, x: coords.x, y: coords.y });
    }
    analytics.logEvent("purchase_success", {
      coordinates: coords,
      price: price,
    });
  } catch (error) {
    if (error.code === 4001) {
      console.log("Please connect to MetaMask.");
    } else {
      console.error(error);
    }
    analytics.logEvent("purchase_failure", {
      coordinates: coords,
      price: price,
      error: error.message,
    });
    $spiner.remove();
    $buyButton.style.display = "flex";
  }
}

async function connectProfile(auth0, tokenId) {
  analytics.logEvent("social_connect_initiate", {
    tokenId: tokenId,
  });
  //logged in. you can get the user profile like this:
  try {
    await auth0.loginWithPopup({});
    const { nickname, sub } = await auth0.getUser();
    const provider = getAuthProvider(sub);
    await changeNameForToken(tokenId, `${provider}:${nickname}`);
    analytics.logEvent("social_connect_success", {
      social_network: provider,
      username: nickname,
    });
    updateUserName(provider, nickname, true);
    // if (process.env.NODE_ENV === "development") {
    await auth0.logout({
      returnTo: window.location,
    });
    // }
  } catch (e) {
    analytics.logEvent("social_connect_failure", {
      error: e.message,
    });
    console.log({
      connectError: e.message,
    });
    await auth0.logout({
      returnTo: window.location,
    });
  }
}

function getAuthProvider(data) {
  if (data.search("github") >= 0) {
    return "github";
  }
  if (data.search("twitch") >= 0) {
    return "twitch";
  }
  if (data.search("twitter") >= 0) {
    return "twitter";
  }
  if (data.search("discord") >= 0) {
    return "discord";
  }
}

async function setMessage(tokenId) {
  analytics.logEvent("time_capsule_initiate", {
    tokenId: tokenId,
  });
  const before = document.querySelector(".time-capsule-before-set");
  const after = document.querySelector(".time-capsule-after-set");
  const textInput = document.querySelector(
    ".product__popup-content-time-capsule-text-input"
  );
  const passInput = document.querySelector(
    ".product__popup-content-time-capsule-password-input"
  );

  const text = textInput.value;
  const password = passInput.value;

  if (!text.length) {
    analytics.logEvent("time_capsule_failure", {
      tokenId: tokenId,
      error: "Text to short",
    });
    textInput.style.border = "solid 1px red";
    return;
  }

  if (!password.length) {
    analytics.logEvent("time_capsule_failure", {
      tokenId: tokenId,
      error: "Passord to short",
    });
    passInput.style.border = "solid 1px red";
    return;
  }

  const encriptedMessage = encrypt(text, password);

  try {
    await changeMessageForToken(tokenId, encriptedMessage);
    analytics.logEvent("time_capsule_success", {
      tokenId: tokenId,
    });
    before.style.display = "none";
    after.style.display = "block";
  } catch (e) {
    console.log(e);
  }
}

async function getPharsePrice(tokenId) {
  //

  const textInput = document.querySelector(
    ".product__popup-content-time-capsule-text-input"
  );
  const passInput = document.querySelector(
    ".product__popup-content-time-capsule-password-input"
  );
  const gasElement = document.querySelector(
    ".product__popup-content-time-capsule-gas"
  );
  const text = textInput.value;
  const password = passInput.value;
  const encriptedMessage = encrypt(text, password);
  const gas = await getGasForMessage(tokenId, encriptedMessage);
  gasElement.innerHTML = gas;
}

function IsTwitterSpace(x, y) {
  const space = twitterSpaces.find((space) => {
    return space.x === x && space.y === y;
  });

  return space ?? false;
}

async function verifyTwitter(auth0, coords, reservedName) {
  analytics.logEvent("social_connect_initiate_marketing", {
    coords,
  });
  //logged in. you can get the user profile like this:
  try {
    await auth0.loginWithPopup({});
    const { nickname, sub } = await auth0.getUser();
    const provider = getAuthProvider(sub);
    analytics.logEvent("social_connect_success_marketing", {
      coords,
      social_network: provider,
      username: nickname,
    });
    return reservedName === nickname;
  } catch (e) {
    analytics.logEvent("social_connect_failure_marketing", {
      error: e.message,
    });
    console.log({
      connectError: e.message,
    });
    await auth0.logout({
      returnTo: window.location,
    });
  }
}

async function getMessage(tokenId, passphrase) {
  console.log("getMessage", { tokenId, passphrase });
  const encriptedMessage = await getTokenMessage(tokenId);
  console.log("encriptedMessage", { encriptedMessage });

  const message = await decrypt(encriptedMessage, passphrase);
  const messageBlock = document.querySelector(".capluse_message");

  messageBlock.innerHTML = message;
}
