import { ethers, providers } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { daiAddress } from "config";

// The ERC-20 Contract ABI, which is a common contract interface
// for tokens (this is the Human-Readable ABI format)
const daiAbi = [
  // Mint
  "function mintNFT(int256 xCoords, int256 yCoords) public payable",

  // Getters
  "function getName(uint256 tokenId) public view returns (string memory)",
  "function getCoordsByTokenId(uint256 tokenId) public view returns (int256, int256)",
  "function getOwnedCoords() public view returns (tuple(int256,int256)[] memory)",
  "function getTokenIdByCoords(int256 x, int256 y) public view returns (uint)",
  "function getMessage(uint256 tokenId) public returns (uint256)",
  "function messageSetPossible(uint256 tokenId) public view returns (bool)",
  "function tokenURI(uint256 tokenId) public view  returns (string memory)",

  //User tokens
  "function balanceOf(address owner) public view returns (uint256)",
  "function tokenOfOwnerByIndex(address owner, uint256 index) public view returns (uint256)",

  // Setters
  "function changeName(uint256 tokenId, string memory newName) public",
  "function setMessage(uint256 tokenId, string memory message) public",

  // Events
  "event SpaceSold(address owner, uint256 id, tuple(int256,int256) coords)",
  "event NameSet(string name, uint256 id, tuple(int256,int256) coords)",
];

export const provider = new WalletConnectProvider({
  infuraId: "84842078b09946638c03157f83405213", // Required
  qrcodeModalOptions: {
    mobileLinks: [
      "rainbow",
      "metamask",
      "argent",
      "trust",
      "imtoken",
      "pillar",
    ],
  },
});

export const web3Provider = new providers.Web3Provider(provider);

// The Contract object
export const contract = new ethers.Contract(daiAddress, daiAbi, web3Provider);
