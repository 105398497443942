import { contract } from "ethereum/provider";

export interface Coords {
  x: string;
  y: string;
}
export interface Token {
  coords: Coords;
  id: string;
}

export const getMyTokens = async (
  address: string,
  updateGrid: (updateRequest: { isMy: boolean; x: string; y: string }) => void
) => {
  const balance = await contract.balanceOf(address);
  const tokensRequests = [];

  for (let i = 0; i < balance; i++) {
    tokensRequests.push(contract.tokenOfOwnerByIndex(address, i));
  }
  if (balance > 0) {
    const tokenIds = await Promise.all(tokensRequests);
    for await (const token of tokenIds) {
      const coords = await contract.getCoordsByTokenId(token.toString());

      updateGrid({
        isMy: true,
        x: coords[0].toString(),
        y: coords[1].toString(),
      });
    }
  }
};

export const getPriceForCoords = async (x: string, y: string) => {
  return 0;
};

export const getOwnedCoords = async () => {
  try {
    const result = await contract.getOwnedCoords();
    return result.map((coords: BigInt) => {
      const splited = coords.toString().split(",");
      return {
        x: splited[0],
        y: splited[1],
      };
    });
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getTokenIdByCoords = async ({ x, y }: Coords) => {
  try {
    const result = await contract.getTokenIdByCoords(x, y);
    return result.toString();
  } catch (e) {
    console.log(e);
  }
};

export const getTokenURI = async (tokenId: string) => {
  try {
    const result = await contract.tokenURI(tokenId);
    return result.toString();
  } catch (e) {
    console.log(e);
  }
};

export const getTokenName = async (tokenId: string) => {
  try {
    const result = await contract.getName(tokenId);
    return result.toString();
  } catch (e) {
    console.log(e);
  }
};

export const getCurrentOrbit = async () => {
  return 17;
};
