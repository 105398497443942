import amplitude from "amplitude-js";
import { amplitudeKey } from "config";
import { WWW } from "./www";
import reportWebVitals from "./reportWebVitals";

import "./index.css";
import "./www.scss";

amplitude.getInstance().init(amplitudeKey);

export const analytics = amplitude.getInstance();

WWW();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

window.addEventListener("beforeunload", () => analytics.logEvent("page_close"));
