import { contract } from "ethereum/provider";
import { updateGrid } from "www/grid/grid";
import { showPopup } from "www/product/product";

export const setUpEvents = (address: string) => {
  console.log("Start listening events");
  contract.on("SpaceSold", (owner: string, id: string, coords: any) => {
    const isMy = address.toLowerCase() === owner.toLowerCase();
    const x = coords[0].toString();
    const y = coords[1].toString();

    updateGrid({
      isMy,
      x,
      y,
    });

    if (isMy) {
      showPopup({
        coord: {
          x,
          y,
        },
        status: isMy ? "Owned by you" : "Taken",
        owner: {
          isMy,
        },
      });
    }
  });
};
