import { mobileCheck } from "../mobile/mobile.js";
let scrollPosition = 0;

export const pageLock = (toggle) => {
  const $body = document.querySelector('body');
  
  if (toggle) {
    if (mobileCheck()) {
        //$body.classList.add("locked");

        scrollPosition = window.scrollY;

        $body.style.overflow = 'hidden';
        $body.style.position = 'fixed';
        $body.style.top = `-${scrollPosition}px`;
        $body.style.width = '100%';

    }    
  } else {
    if (mobileCheck()) {
        //$body.classList.remove("locked");

        $body.style.removeProperty('overflow');
        $body.style.removeProperty('position');
        $body.style.removeProperty('top');
        $body.style.removeProperty('width');

        window.scrollTo(0, scrollPosition);
    }    
  }
};
