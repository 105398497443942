import { showPopup } from "../product/product.js";
import {
  colorsClear,
  colorsSold,
  colorsYou,
  colorsLocked,
} from "../colors/colors.js";

const $grid = document.querySelector(".grid");
let $gridCells;
let inlineSvg;

let startPos = -16;
let maxPos = 16;

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const renderGrid = (round, coords, myTokens, callback) => {
  $grid.innerHTML = inlineSvg;
  $grid.classList.remove("grid_placeholder");

  let roundStart = startPos + round;
  let roundEnd = maxPos - round;

  $gridCells = $grid.querySelectorAll("rect");

  for (let $c of $gridCells) {
    $c.setAttribute(
      "fill",
      colorsClear[randomIntFromInterval(0, colorsClear.length - 1)]
    );
    $c.setAttribute("stroke", "none");
  }

  let count = 0;

  for (let r = startPos; r <= maxPos; r++) {
    for (let c = startPos; c <= maxPos; c++) {
      //draw locked
      if (
        r >= roundStart &&
        r <= roundEnd &&
        c >= roundStart &&
        c <= roundEnd
      ) {
        $gridCells[count].classList.add("grid__item-svg_locked");
        $gridCells[count].style.fill =
          colorsLocked[randomIntFromInterval(0, colorsLocked.length - 1)];
      } else {

        //draw sold out
        for (let co in coords) {
          if (c === +coords[co].x && r === +coords[co].y) {
            $gridCells[count].classList.add("grid__item-svg_sold-to-other");
            $gridCells[count].style.fill =
              colorsSold[randomIntFromInterval(0, colorsSold.length - 1)];
          }
        }

        for (let co in myTokens) {
          if (c === +myTokens[co].coords.x && r === +myTokens[co].coords.y) {
            $gridCells[count].classList.add("grid__item-svg_sold-to-you");
            $gridCells[count].style.fill =
              colorsYou[randomIntFromInterval(0, colorsYou.length - 1)];
          }
        }

      }

      count++;
    }
  }

  if (callback) callback();
};

export const renderClosedRoundCover = (round) => {
  console.log({ round });
  let roundStart = startPos + round;
  let roundEnd = maxPos - round;

  $gridCells = $grid.querySelectorAll("rect");

  let count = 0;

  for (let r = startPos; r <= maxPos; r++) {
    for (let c = startPos; c <= maxPos; c++) {
      //draw locked
      if (
        r >= roundStart &&
        r <= roundEnd &&
        c >= roundStart &&
        c <= roundEnd
      ) {
        $gridCells[count].classList.add("grid__item-svg_locked");
        $gridCells[count].style.fill =
          colorsLocked[randomIntFromInterval(0, colorsLocked.length - 1)];
      } else {
        $gridCells[count].classList.remove("grid__item-svg_locked");
      }
      count++;
    }
  }
};

export const updateGrid = ({ isMy, x, y }) => {
  const cell = document.querySelector(`rect[data-x='${x}'][data-y='${y}']`);

  if (isMy) {
    cell.classList.remove("grid__item-svg_sold-to-other");
    cell.classList.add("grid__item-svg_sold-to-you");
    cell.style.fill = colorsYou[randomIntFromInterval(0, colorsYou.length - 1)];
  } else {
    cell.classList.add("grid__item-svg_sold-to-other");
    cell.style.fill =
      colorsSold[randomIntFromInterval(0, colorsSold.length - 1)];
  }
};
function addEventListenersToCells() {
  $grid.addEventListener("click", (e) => {
    if (e.target.classList.contains("grid__item-svg")) {
      renderPopup(e.target);
    }
  });
}

export const renderPopup = (target) => {
  for (let $c of $gridCells) {
    $c.classList.remove("grid__item-svg_selected");
  }

  const data = {
    coord: {
      x: +target.getAttribute("data-x"),
      y: +target.getAttribute("data-y"),
    },
    status: ((target) => {
      let s = "Available";
      if (target.classList.contains("grid__item-svg_sold-to-other"))
        s = "Taken";
      if (target.classList.contains("grid__item-svg_sold-to-you"))
        s = "Owned by you";
      if (target.classList.contains("grid__item-svg_locked"))
        s = "Not yet available";

      return s;
    })(target),
    owner: {
      isMy: Boolean(target.classList.contains("grid__item-svg_sold-to-you")),
      name: "Name",
    },
  };

  target.classList.add("grid__item-svg_selected");
  showPopup(data);
};

export const featchGrid = (round, coords, myTokens, callback) => {
  fetch("./assets/grid.svg")
    .then((response) => response.text())
    .then((str) => {
      inlineSvg = str;
      addEventListenersToCells();

      if (callback) callback();
    });
};
