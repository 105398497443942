export const videoPlayer = () => {
    const $video = document.querySelector('#video');

    document.addEventListener('DOMContentLoaded', function(){
     $video.play();  
    });  
  
    $video.addEventListener('click', function(){
      $video.play();
    });
}
