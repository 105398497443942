import {
  getPerimeter,
  isSpecial,
  isCenter,
  getPerimeterCount,
} from "./coords.js";
import { utils } from "ethers";

const baseImgPath =
  "https://cloudflare-ipfs.com/ipfs/QmNkpM6vtp61PSmvHEsnzh8s8y4Mmnj1xL8m1nYiUYpedD/";

export const updateUserName = (platform, tokenName) => {
  const nameBlock = document.querySelector(".name-block");
  nameBlock.childNodes.forEach((node) => {
    node.remove();
  });
  const newTemplate = getNameTmpl(platform, tokenName, true);
  nameBlock.innerHTML = `
    <svg class="graph" width="43" height="43" viewBox="0 0 43 43">
      <use href="#def-user" />
    </svg>
    ${newTemplate}
  `;
};

export const template = ({
  data,
  platform,
  tokenName,
  isMy,
  notAvailable,
  ownedByOther,
  isSold,
  price,
  revealPhraseDate,
  tokenId,
  isReserved,
  isMessageSetPossible,
}) => {
  /*
    TEST 
    **/
  //isMy = true;

  let loaded = false;
  if (
    isMy !== null &&
    platform !== null &&
    tokenName !== null &&
    notAvailable !== null &&
    ownedByOther !== null &&
    isSold !== null &&
    price !== null &&
    revealPhraseDate !== null &&
    tokenId !== null &&
    isMessageSetPossible !== null
  ) {
    loaded = true;
  }

  //Description
  const __descriptionTpl = ((coord) => {
    const perimeter = getPerimeter(coord);
    const perimeterCounter = getPerimeterCount(perimeter);
    let resultTpl = "";
    if (isCenter(coord)) {
      resultTpl = `<p>This is the central Plot of the entire Gone to Mars system. It’s one of a kind!</p>`;
    } else {
      let specialTpl = isSpecial(coord)
        ? `It's also 1 of 8 special Plots located on the axes and diagonals of that Perimeter.`
        : "";
      resultTpl = `<p>This Plot belongs to Perimeter <strong>${perimeter}</strong> that contains a total 
      of <strong>${perimeterCounter}</strong> Plots. ${specialTpl}</p>`;
    }
    return resultTpl;
  })(data.coord);

  //Items
  const __items = {
    coord: () => {
      const { x, y } = data.coord;
      return `<div class="product__popup-content-item">
          <svg class="graph" width="43" height="43" viewBox="0 0 43 43">
            <use href="#def-coord" />
          </svg>
          <span>[x: ${x}, y: ${y}]</span>
      </div>`;
    },

    price: () => {
      const p = loaded
        ? `Price ${utils.formatEther(price).slice(0, 6)} ETH`
        : "Loading...";
      return `<div class="product__popup-content-item">
          <svg class="graph" width="43" height="43" viewBox="0 0 43 43">
            <use href="#def-price" />
          </svg>                    
          <span>${p}</span>
      </div>`;
    },

    username: () => {
      let resultTpl = "";

      if (isSold || isReserved) {
        let nickNameTpl;

        if (loaded || isReserved) {
          nickNameTpl = getNameTmpl(platform, tokenName, isMy, isReserved);

          if (isMy && tokenName && tokenName !== "Anonymous") {
            nickNameTpl += `<button class="button button_size-xs button_border product__popup-content-connect-social-network-button">Reconnect</button>`;
          }
        } else {
          nickNameTpl = "Loading...";
        }

        resultTpl = `
        <div class="product__popup-content-item name-block">
          <svg class="graph" width="43" height="43" viewBox="0 0 43 43">
            <use href="#def-user" />
          </svg>
          ${nickNameTpl} 
        </div>`;
      }

      return resultTpl;
    },

    status: () => {
      const s = data.status;
      const statusColor = (s) => {
        let color;
        if (s === "Available") color = "#aadfee";
        if (s === "Taken") color = "#7b6aee";
        if (s === "Owned by you") color = "#ee6969";
        if (s === "Not yet available") color = "#e3eeee";

        return color;
      };
      const token =
        ["Taken", "Owned by you"].includes(s) && loaded ? ` #${tokenId}` : "";

      return `<div class="product__popup-content-item">
          <div class="product__popup-content-status" style="background-color:${statusColor(
            s
          )}" ></div>
          <span>${s}${token}</span>
      </div>`;
    },
  };

  //Buttons
  const __buttons = () => {
    let resultTpl = "";
    if (isReserved) {
      resultTpl += `<button class="button button_size-s product__popup-content-verify-twitter">VERIFY TWITTER ACCOUNT</button>`;
      return resultTpl;
    }
    if (isMy || isSold) {
      resultTpl += `
        <a class="button button_size-s product__popup-content-opensea-button" target="_blank" href="https://opensea.io/assets/0xAA9E97894D01412aB31c5ddb01F49A09e66e39a6/${tokenId}">View on OpenSea</a>
        <a class="button button_size-s button_border" target="_blank" href="${baseImgPath}${data.coord.x}x${data.coord.y}.jpg">View on IPFS</a>
      `;
    }
    if (isMy) {
      resultTpl += `
      <a class="button button_size-s button_ghost button_share" href="#">
        <svg class="graph" width="43" height="43" viewBox="0 0 43 43">
          <use href="#def-share" />
        </svg>
      </a>`;
    }
    if (!notAvailable && !isSold) {
      const btnBuyText = loaded
        ? `Claim for ${utils.formatEther(price).slice(0, 6)} ETH`
        : "Claim";
      resultTpl += `<button class="button button_size-s product__popup-content-buy-button">${btnBuyText}</button>`;
    }
    return loaded ? resultTpl : "";
  };

  //Information panel
  const __informationPanel = () => {
    return `
    <input name="tabs" type="radio" id="information" checked />
    <div class="product__popup-tab-content">
      <div class="product__popup-content-items">
          ${__items.username()}
          ${__items.coord()}
          ${__items.price()}
          ${__items.status()}
      </div>
      <div class="product__popup-content-tools">
          <div class="product__popup-description">${__descriptionTpl}</div>
          <div class="product__popup-content-controls">
            ${__buttons()}                      
          </div>
      </div>
    </div>
  `;
  };

  const __timeCapsulePanel = () => {
    return `
    <input name="tabs" type="radio" id="time-capsule" >
    <div class="product__popup-tab-content">
        ${
          isMy || data.status === "Owned by you"
            ? `<div class="time-capsule">
                <div class="time-capsule-before-set">
                    <p>This is the Time Capsule, your message to future generations. 
                    Once sealed, it can only be revealed after 2050. Keep it short and try to convey something meaningful, 
                    you're addressing your descendants after all. Also, the shorter the message, the lower the gas fee. 
                    Store safely the keyword and pass it to your descendants together with your NFT.
                    </p>
                    <textarea class="input input_size-s product__popup-content-time-capsule-text-input" placeholder="Message" required></textarea>
                    <input type="text" class="input input_size-s product__popup-content-time-capsule-password-input" placeholder="Keyword" required/>
                    <div class="product__popup-content-controls">
                      <button class="button button_size-s product__popup-content-set-time-capsule-button">Seal my Time Capsule</button>
                      <button class="button button_size-s product__popup-content-time-capsule-get-gas-button">Check Gas Fee</button>
                    </div>  
                    <p style="margin-bottom: 0;">Estimated gas fee: <span class="product__popup-content-time-capsule-gas">0</span></p>
                </div>
              <div class="time-capsule-after-set">
                  ${
                    revealPhraseDate
                      ? `
                        <input type="text" class="input input_size-s product__popup-content-time-capsule-password-reveal-input" placeholder="Keyword" required/>
                        <button class="button button_size-s product__popup-content-time-capsule-get-message">Get message</button>
                        <p class="capluse_message"></p>
                      `
                      : `<p>The Time Capsule is sealed until 2050. You'll need the Keyword to unseal it.</p>
                      <svg class="graph" viewBox="0 0 704 94">
                        <use href="#def-illustration_6"></use>
                      </svg>`
                  }

              </div>
        </div>`
            : ""
        }
    </div>
  `;
  };

  //Popup
  return `
        <div class="product__popup-close">
            <svg class="graph" width="37" height="37" viewBox="0 0 37 37">
               ${loaded ? `<use href="#def-close" />` : ""}
            </svg>      
        </div>
        <div class="product__popup-inner">
            <div class="product__popup-image">
              <img alt="Plot [x:${data.coord.x},y:${
    data.coord.y
  }]" src="${baseImgPath}${data.coord.x}x${data.coord.y}.jpg" />
            </div>
            <div class="product__popup-content">
                <div class="product__popup-tabs-header">
                    <label class="product__popup-tabs-header-label product__popup-tabs-header-label_active" for="information">Details</label>
                    ${
                      (isMy || data.status === "Owned by you") && loaded
                        ? `<label class="product__popup-tabs-header-label" for="time-capsule">Time Capsule</label>`
                        : ""
                    }
                </div>
                ${__informationPanel()}
                ${loaded ? __timeCapsulePanel() : ""}
            </div>
        </div>
    `;
};

function getNameTmpl(platform, tokenName, isMy, isReserved) {
  const link = createLinkToProvider(platform, tokenName);
  let template =
    platform !== "discord" && tokenName && tokenName !== "Anonymous"
      ? `
        <span class="product__popup-content-username">
          ${isReserved ? "Reserved for " : ""}
          <a href="${link}" >${tokenName}</a>
        </span>`
      : isMy
      ? `<button class="button button_size-xs button_border product__popup-content-connect-social-network-button">Link social account</button>`
      : `<span>${tokenName}</span>`;

  return template;
}

function createLinkToProvider(provider, tokenName) {
  switch (provider) {
    case "github":
      return `https://github.com/${tokenName}`;
    case "twitch":
      return `https://www.twitch.tv/${tokenName}`;
    case "twitter":
      return `https://twitter.com/${tokenName}`;
    default:
      return ``;
  }
}
