export const renderShare = () => {
  const twitterTpl = `
    <div class="share__popup">
        <div class="share__popup-close">
            <svg class="graph" width="37" height="37" viewBox="0 0 37 37">
                <use href="#def-close" />
            </svg>
        </div>    
        <div class="share__controls">
            <input type="text" class="input input_size-s" value="${window.location.href}" autofocus />
        </div>
        <p class="share__status"></p>
    </div>    
    `;

  const $sharePopup = document.createElement("div");
  const $product = document.querySelector(".product");

  $sharePopup.classList.add("share");
  $sharePopup.innerHTML = twitterTpl;
  const $closeBtn = $sharePopup.querySelector(".share__popup-close");
  const $urlInput = $sharePopup.querySelector(".input");
  const $shareStatus = $sharePopup.querySelector(".share__status");

  $urlInput.focus();

  $urlInput.addEventListener("click", function(e){
    this.setSelectionRange(0, this.value.length);
    document.execCommand("copy");
    $shareStatus.textContent = "Link copied";
  });

  $closeBtn.addEventListener("click", () => {
    $sharePopup.remove();
  });

  $product.append($sharePopup);
};
