const env: string = process.env.REACT_APP_APP_ENV || "development";

const amplitudeKeys = {
  production: "4d1010e8a708a5f8e745f5eb3fbfe2ba",
  development: "4d1010e8a708a5f8e745f5eb3fbfe2ba",
  // development: "07dc99e8a767e6b808e5f1c4097375d3",
  test: "",
};

const daiAddresses = {
  production: "0xAA9E97894D01412aB31c5ddb01F49A09e66e39a6",
  development: "0xAA9E97894D01412aB31c5ddb01F49A09e66e39a6",
  // development: "0x7953594aFEe6a93d68e94D36c2076b54C1370e05",
  test: "",
};

const etheriumNodes = {
  production: "https://cloudflare-eth.com",
  development: "https://rinkeby.infura.io/v3/84842078b09946638c03157f83405213",
  test: "",
};

// @ts-ignore: Unreachable code error
export const amplitudeKey = amplitudeKeys[env];
// @ts-ignore: Unreachable code error
export const daiAddress = daiAddresses[env];
// @ts-ignore: Unreachable code error
export const etheriumNode = etheriumNodes[env];

// export const twitterSpaces = [
//   {
//     x: -13,
//     y: 16,
//     nickname: "tropoFarmer",
//   },

//   {
//     x: -11,
//     y: 16,
//     nickname: "RealAllenHena",
//   },

//   {
//     x: -8,
//     y: 16,
//     nickname: "AndrewSteinwold",
//   },

//   {
//     x: 0,
//     y: 16,
//     nickname: "j1mmyeth",
//   },

//   {
//     x: 6,
//     y: 16,
//     nickname: "Debussy100",
//   },

//   {
//     x: 12,
//     y: 16,
//     nickname: "pents90",
//   },

//   {
//     x: 13,
//     y: 16,
//     nickname: "matthall2000",
//   },

//   {
//     x: 16,
//     y: 11,
//     nickname: "fvckrender",
//   },

//   {
//     x: 16,
//     y: 8,
//     nickname: "CSURAM88",
//   },

//   {
//     x: 16,
//     y: 4,
//     nickname: "Kenshiro_NFT",
//   },

//   {
//     x: 16,
//     y: 1,
//     nickname: "sc",
//   },

//   {
//     x: 16,
//     y: -3,
//     nickname: "RobbieSTrevino",
//   },

//   {
//     x: 16,
//     y: -5,
//     nickname: "SuperRareRoses",
//   },

//   {
//     x: 16,
//     y: -8,
//     nickname: "seedphrase",
//   },

//   {
//     x: 16,
//     y: -11,
//     nickname: "ArtOnBlockchain",
//   },

//   {
//     x: 16,
//     y: -13,
//     nickname: "pixls_dot_eth",
//   },

//   {
//     x: -16,
//     y: 12,
//     nickname: "jakeosmun",
//   },

//   {
//     x: -16,
//     y: 9,
//     nickname: "DeezeFi",
//   },

//   {
//     x: -16,
//     y: 7,
//     nickname: "dingalingts",
//   },

//   {
//     x: -16,
//     y: 4,
//     nickname: "NFTland",
//   },

//   {
//     x: -16,
//     y: 0,
//     nickname: "RoninDaCC",
//   },

//   {
//     x: -16,
//     y: -3,
//     nickname: "mattmedved",
//   },

//   {
//     x: -16,
//     y: -6,
//     nickname: "Loopifyyy",
//   },

//   {
//     x: -16,
//     y: -8,
//     nickname: "_jeffnicholas_",
//   },

//   {
//     x: -16,
//     y: -11,
//     nickname: "PRguitarman",
//   },

//   {
//     x: -16,
//     y: -13,
//     nickname: "DCLBlogger",
//   },

//   {
//     x: -10,
//     y: -16,
//     nickname: "TheSmarmyBum",
//   },
//   {
//     x: -7,
//     y: -16,
//     nickname: "gmoneyNFT",
//   },

//   {
//     x: -4,
//     y: -16,
//     nickname: "punk4156",
//   },

//   {
//     x: 1,
//     y: -16,
//     nickname: "peruggia_v",
//   },

//   {
//     x: 4,
//     y: -16,
//     nickname: "RealmissNFT",
//   },

//   {
//     x: 7,
//     y: -16,
//     nickname: "NateAlexNFT",
//   },

//   {
//     x: 10,
//     y: -16,
//     nickname: "NFTgirl",
//   },

//   {
//     x: 12,
//     y: -16,
//     nickname: "pranksyNFT",
//   },

//   {
//     x: 14,
//     y: -16,
//     nickname: "beaniemaxi",
//   },
//   {
//     x: -5,
//     y: -16,
//     nickname: "mikethreezy",
//   },

//   {
//     x: -6,
//     y: -16,
//     nickname: "maxcapacity",
//   },
// ];

export const twitterSpaces = [];
