import * as cryptoJs from "crypto-js";

export const encrypt = async (text: string, passphrase: string) => {
  return cryptoJs.AES.encrypt(text, passphrase).toString();
};

export const decrypt = async (ciphertext: string, passphrase: string) => {
  const bytes = cryptoJs.AES.decrypt(ciphertext, passphrase);
  const originalText = bytes.toString(cryptoJs.enc.Utf8);
  return originalText;
};
