import {
  renderGrid,
  updateGrid,
  featchGrid,
  renderPopup,
} from "./www/grid/grid.js";
import { renderCursor, mapZoom } from "./www/map/map.js";
import { renderSectionHeader } from "./www/frame/frame.js";
import { mobileCheck, mobileHackHeight } from "./www/mobile/mobile.js";
import { videoPlayer } from "./www/video/video.js";
import { renderTwitter } from "./www/twitter/twitter.js";
import { renderRandomPlace } from "./www/random-place/random-place.js";
import {
  getMyTokens,
  getOwnedCoords,
  getCurrentOrbit,
  // checkTransferForToken,
} from "ethereum/contract";
import { setUpEvents } from "ethereum/events";
import { getWalletAddress } from "ethereum/wallets";
import { analytics } from "index";
import { twitterSpaces } from "config";

//Turn to TRUE if you're sure
const SALE = true;

export const WWW = async () => {
  featchGrid(0, [], [], renderGridWithData);
  analytics.logEvent("page_view");

  videoPlayer();
  mobileHackHeight();
  renderSectionHeader();
  if (!mobileCheck()) renderCursor();
  if (!SALE) renderTwitter();

  mapZoom();
};

export async function renderGridWithData() {
  const address = await getWalletAddress();
  const currentOrbit = await getCurrentOrbit();
  const coords = await getOwnedCoords();
  renderGrid(
    Number(currentOrbit),
    [...twitterSpaces, ...coords],
    [],
    function () {
      console.log({ currentOrbit });
    }
  );
  getLocation();
  renderRandomPlace(twitterSpaces);

  if (address) {
    await getMyTokens(address, updateGrid);
    setUpEvents(address);
  }
}

function getLocation() {
  //detect loacation on load
  if (window.location.search) {
    const urlParams = new URLSearchParams(window.location.search);
    const x = urlParams.get("x"),
      y = urlParams.get("y");
    const $map = document.querySelector("#map");
    if (
      x !== null &&
      y !== null &&
      Number.isInteger(+x) &&
      Number.isInteger(+y)
    ) {
      const $element = document.querySelector(
        `.grid__item-svg[data-x="${x}"][data-y="${y}"]`
      );

      if ($element) {
        window.scrollTo({
          top: $map.offsetTop - window.innerHeight / 2,
          left: 0,
          behavior: "smooth",
        });

        renderPopup($element);
      }
    }
  }
}
